import request from '@/utils/request'; // 商品列表

export function list(data) {
  return request({
    url: '/product/list',
    method: 'get',
    params: data
  });
} // 商品详情

export function detail(data) {
  return request({
    url: '/product/detail',
    method: 'get',
    params: data
  });
} // 轮播图

export function rotation(data) {
  return request({
    url: '/rotation',
    method: 'get',
    params: data
  });
}